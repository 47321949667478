<template>
  <div class="mycontainer">
    <el-container>
      <el-header class="header">
        <div class="content">
          <template
            v-if="userInfo.userType === '2' || userInfo.userType === '3'"
          >
            <span>当前门店：</span>
            <el-button type="primary" plain @click="showShopList = true"
              >{{ shopName }}
            </el-button>
          </template>
          <template v-else>
            <span v-if="userInfo.userType === '0'">Admin</span>
            <span v-else-if="userInfo.userType === '1'">客服账户</span>
            <span v-else-if="userInfo.userType === '4'">车主账户</span>
            <span v-else-if="userInfo.userType === '5'">运营人员</span>
          </template>
          <div class="flex-1"></div>
          <el-image
            class="mr-4"
            style="width: 1rem; height: 1rem"
            :src="QrCodeImg"
            :preview-src-list="srcList"
            :initial-index="0"
            fit="cover"
            :z-index="1000"
            preview-teleported
          />
          <el-icon :size="14"><UserFilled /></el-icon>
          <span class="text-sm ml-1">{{ userInfo.name }}</span>
          <a class="cursor-pointer ml-6 text-sm" @click="showChangePwd = true"
            >修改密码
          </a>
          <el-divider direction="vertical" />
          <a class="cursor-pointer text-sm" @click="showLogout = true"
            >安全退出
          </a>
        </div>
      </el-header>
      <el-container>
        <el-aside width="11.80rem">
          <el-scrollbar
            :height="menuHeight"
            class="border-gray-300 border-solid border-0 border-r"
          >
            <div class="menu-list">
              <div class="text-center">
                <el-dropdown trigger="click" @command="handleCommand">
                  <img
                    width="100"
                    height="100"
                    class="cursor-pointer"
                    src="../assets/imgs/logo.png"
                  />
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item command="a">修改密码</el-dropdown-item>
                      <el-dropdown-item divided command="b"
                        >安全退出
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
              <el-divider
                border-style="dotted"
                style="margin-top: 0; margin-bottom: 0"
              />
              <el-menu
                style="border: none"
                :default-active="defaultActive"
                :unique-opened="true"
                :router="false"
                active-text-color="#ff6e3f"
                v-loading="menuLoading"
              >
                <el-menu-item index="/home" @click="clickHome">
                  <el-icon>
                    <HomeFilled />
                  </el-icon>
                  <span>首页</span>
                </el-menu-item>
                <el-sub-menu
                  v-for="menu in menuList"
                  :index="menu.id"
                  :key="menu.id"
                >
                  <template #title>
                    <el-icon>
                      <component :is="menu.attributes.icon" />
                    </el-icon>
                    <span>{{ menu.text }}</span>
                  </template>
                  <div v-for="subItem in menu.children" :key="subItem.id">
                    <template v-if="subItem.hasChildren">
                      <el-sub-menu :index="subItem.id">
                        <template #title>{{ subItem.text }}</template>
                        <el-menu-item
                          v-for="child in subItem.children"
                          :index="child.path"
                          :key="child.id"
                          @click="clickMenuItem(child)"
                          >{{ child.text }}
                        </el-menu-item>
                      </el-sub-menu>
                    </template>
                    <template v-else>
                      <el-menu-item
                        v-if="subItem.text === '汽配经'"
                        :index="subItem.path"
                      >
                        <span @click.stop="qpjLogin">打开汽配经</span>
                      </el-menu-item>
                      <el-menu-item
                        v-else
                        :index="subItem.path"
                        @click="clickMenuItem(subItem)"
                        >{{ subItem.text }}
                      </el-menu-item>
                    </template>
                  </div>
                </el-sub-menu>
              </el-menu>
            </div>
          </el-scrollbar>
        </el-aside>
        <el-main style="padding: 0">
          <el-scrollbar :height="menuHeight">
            <router-view v-if="showContent"></router-view>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
  </div>
  <el-dialog
    v-model="showShopList"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="选择门店"
    width="28%"
  >
    <ChooseShop @setShop="setShop" @close="showShopList = false" />
  </el-dialog>
  <el-dialog
    v-model="showLogout"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="提示"
    width="25%"
  >
    <span>确定退出登录吗？</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showLogout = false">取消</el-button>
        <el-button type="primary" @click="submitLogout()">退出</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="showChangePwd"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="修改密码"
    width="38%"
  >
    <ResetPwd @close="showChangePwd = false" />
  </el-dialog>
</template>

<style lang="scss" scoped>
.mycontainer {
  text-align: left;
  padding: 0;
  height: 100%;
  .menu-list {
    height: 100%;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
  }
}
.el-divider--horizontal {
  margin: 0.75rem 0;
}
.header {
  background-color: #ff6e3f;
  .content {
    display: flex;
    align-items: center;
    height: 100%;
    color: white;
    padding: 0 1.25rem;
  }
}
</style>
<script lang="ts" setup>
import { ref, onMounted, Ref, defineAsyncComponent } from "vue";
import { HomeFilled, UserFilled } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { useUserInfoStore, useMenuStore } from "@/stores";
import useCurrentInstance from "@/utils/useCurrentInstance";
import { getBrowser, openNewTab } from "@/utils/browser";
import QrCodeImg from "@/assets/imgs/qrcode.png";
import storage from "@/utils/storage";

const ResetPwd = defineAsyncComponent(
  () => import("@/views/user/ResetPwd.vue")
);
const ChooseShop = defineAsyncComponent(
  () => import("@/views/shshop/ChooseShop.vue")
);
// Composition API 上下文对象 不能使用ctx对象  使用proxy
const { proxy } = useCurrentInstance();
const srcList = ["/miniprogram.png", "/mdminiprogram.jpg", "/wxopen.jpg"];
const menuList: Ref<MenuType[]> = ref([]);
const router = useRouter();
const defaultActive = ref("/home");
const showContent = ref(true);
const menuLoading = ref(false);
const showLogout = ref(false);
const showChangePwd = ref(false);
const menuStore = useMenuStore();
const userInfoStore = useUserInfoStore();
const showShopList = ref(false);
const userInfo = userInfoStore.getUserInfo();
const shopName = ref(userInfoStore.getShopName());

let screenHeight: number = document.body.clientHeight;
const menuHeight = ref(screenHeight * 1 - 56 + "px");
var modules: Record<string, () => Promise<unknown>>;
var prePath = "/";
onMounted(() => {
  modules = import.meta.glob("/src/views/**/*.vue");
  defaultActive.value = window.location.hash.replaceAll("#", "");
  loadData();
});
interface AttributesType {
  url: string;
  icon?: string;
}
interface MenuType {
  hasChildren: boolean;
  id: string;
  parentId: string;
  text: string;
  path: string;
  attributes: AttributesType;
  children: MenuType[];
}
const clickHome = () => {
  if (prePath === "/") {
    showContent.value = false;
    setTimeout(() => {
      proxy.$router.replace("/"); // 跳转到首页
      showContent.value = true;
    }, 100);
  } else {
    prePath = "/";
    proxy.$router.replace("/");
  }
};
const clickMenuItem = (menuItem: any) => {
  if (prePath === menuItem.path) {
    showContent.value = false;
    setTimeout(() => {
      proxy.$router.replace(menuItem.path);
      showContent.value = true;
    }, 100);
  } else {
    prePath = menuItem.path;
    proxy.$router.replace(menuItem.path);
  }
};
const handleCommand = (command: string) => {
  if (command === "a") {
    // 修改密码
    showChangePwd.value = true;
  } else if (command === "b") {
    // 退出登陆
    showLogout.value = true;
  }
};
const setShop = (sId: string, sName: string) => {
  userInfoStore.setShopId(sId);
  userInfoStore.setShopName(sName);
  shopName.value = sName;
};
const qpjLogin = async () => {
  try {
    let newTab = window.open("about:blank");
    const res = await proxy?.$request.post({
      url: "/chgj/erp/qpjLogin",
      data: "",
      showLoading: true,
    });
    if (newTab) {
      newTab.location.href = res.data;
    } else {
      openNewTab(res.data);
    }
  } catch (err) {
    proxy.$logger.error(err);
  }
};
const loadData = async () => {
  try {
    menuLoading.value = true;
    const res = await proxy?.$request.get({
      url: "/chgj/userMenu/userMenuList",
      params: "",
      showLoading: false,
    });
    let resList = res.result;
    let firstMenu = resList[0];
    if (firstMenu.text == "首页") {
      resList.splice(0, 1);
    }
    resList.forEach((menu: MenuType) => {
      // 一级都是目录
      if (menu.hasChildren) {
        menu.children.forEach((element: any) => {
          if (element.hasChildren) {
            // 二级目录继续遍历
            element.children.forEach((item: any) => {
              // 三级都是菜单
              handleMenuItem(item);
            });
          } else {
            // 二级菜单
            handleMenuItem(element);
          }
        });
      } else {
        handleMenuItem(menu);
      }
    });
    menuList.value = resList;
    menuStore.updateRouter();
  } catch (err: any) {
    proxy.$logger.error("err-->", err);
  }
  menuLoading.value = false;
};
const handleMenuItem = (menuItem: MenuType) => {
  if (menuItem.attributes.url === "chgj/ggInsurance") {
    // 保险/代理人管理------保险公司管理
    menuItem.path = "/ggInsurance";
    addRouter("ggInsurance", menuItem.path, "ggInsurance/GgInsurance");
  } else if (menuItem.attributes.url === "chgj/ggInsuranceDict") {
    // 代理人管理
    menuItem.path = "/ggInsuranceDict";
    addRouter(
      "ggInsuranceDict",
      menuItem.path,
      "ggInsuranceDict/GgInsuranceDict"
    );
  } else if (menuItem.attributes.url === "chgj/ggAgent") {
    // 代理人管理
    menuItem.path = "/ggAgent";
    addRouter("ggAgent", menuItem.path, "ggAgent/GgAgent");
  } else if (menuItem.attributes.url === "chgj/ggXlc") {
    // 修理厂管理
    menuItem.path = "/ggXlc";
    addRouter("ggXlc", menuItem.path, "ggXlc/GgXlc");
  } else if (menuItem.attributes.url === "chgj/brandShOrder") {
    // 定损核价单管理------核价单分成管理
    menuItem.path = "/brandShOrder";
    addRouter("brandShOrder", menuItem.path, "brandShOrder/brandShOrder");
  } else if (menuItem.attributes.url === "chgj/ggFenCheng") {
    // 定损核价单管理------核价单分成管理
    menuItem.path = "/ggFenCheng";
    addRouter("ggFenCheng", menuItem.path, "ggFenCheng/GgFenCheng");
  } else if (menuItem.attributes.url === "chgj/ggShOrder") {
    // 定损核价单管理
    menuItem.path = "/ggShOrder";
    addRouter("ggShOrder", menuItem.path, "ggShOrder/GgShOrder");
  } else if (menuItem.attributes.url === "chgj/market") {
    // 卡券营销------营销管理
    menuItem.path = "/market";
    addRouter("market", menuItem.path, "market/shquan/ShQuan");
  } else if (menuItem.attributes.url === "chgj/shTicket") {
    // 优惠券
    menuItem.path = "/shTicket";
    addRouter("shTicket", menuItem.path, "market/shticket/ShTicket");
  } else if (menuItem.attributes.url === "chgj/market/shTcQuan") {
    // 参与平台活动
    menuItem.path = "/shTcQuan";
    addRouter("shTcQuan", menuItem.path, "market/shtcquan/ShTcQuan");
  } else if (menuItem.attributes.url === "chgj/market/shHxPc") {
    // 核销平台券
    menuItem.path = "/shHxPc";
    addRouter("shHxPc", menuItem.path, "market/shhxpc/ShHxPc");
  } else if (menuItem.attributes.url === "chgj/market/shTcOrder") {
    // 车主优惠券订单
    menuItem.path = "/shTcOrder";
    addRouter("shTcOrder", menuItem.path, "market/shtcorder/ShTcOrder");
  } else if (menuItem.attributes.url === "chgj/market/userFx") {
    // 员工分销
    menuItem.path = "/userFx";
    addRouter("userFx", menuItem.path, "market/userfx/UserFx");
  } else if (menuItem.attributes.url === "chgj/market/jyOrder") {
    // 搭电/轮胎救援
    menuItem.path = "/shJyOrder";
    addRouter("shJyOrder", menuItem.path, "shjyorder/ShJyOrder");
  } else if (menuItem.attributes.url === "chgj/chAttention") {
    // 店铺粉丝
    menuItem.path = "/chAttention";
    addRouter("chAttention", menuItem.path, "chAttention/ChAttention");
  } else if (menuItem.attributes.url === "chgj/shCar") {
    // 车辆档案------客户关系
    menuItem.path = "/shCar";
    addRouter("shCar", menuItem.path, "shcar/ShCar");
  } else if (menuItem.attributes.url === "chgj/shVip") {
    // 会员档案
    menuItem.path = "/shVip";
    addRouter("shVip", menuItem.path, "shvip/ShVip");
  } else if (menuItem.attributes.url === "chgj/shOrderVisit") {
    // 客户回访
    menuItem.path = "/shVisit";
    addRouter("shVisit", menuItem.path, "shvisit/ShVisit");
  } else if (menuItem.attributes.url === "chgj/shOrder") {
    // 工单管理------工单管理
    menuItem.path = "/shOrder";
    addRouter("shOrder", menuItem.path, "shorder/ShOrder");
  } else if (menuItem.attributes.url === "chgj/shLyOrder") {
    // 领料出库
    menuItem.path = "/shLyOrder";
    addRouter("shLyOrder", menuItem.path, "shlyorder/ShLyOrder");
  } else if (menuItem.attributes.url === "chgj/useRecord") {
    // 次卡使用记录
    menuItem.path = "/useRecord";
    addRouter("useRecord", menuItem.path, "shorder/QuanUseRecord");
  } else if (menuItem.attributes.url === "chgj/useRecord/cardUseRecord") {
    // 充值卡使用记录
    menuItem.path = "/cardUseRecord";
    addRouter("cardUseRecord", menuItem.path, "shorder/CardUseRecord");
  } else if (menuItem.attributes.url === "chgj/bbOrder") {
    // 历史数据迁移
    menuItem.path = "/bbHisOrder";
    addRouter("bbHisOrder", menuItem.path, "bborder/BbOrder");
  } else if (menuItem.attributes.url === "chgj/shBuyProduct") {
    // 零散采购-----采购管理
    menuItem.path = "/shBuyProduct";
    addRouter("shBuyProduct", menuItem.path, "shbuyproduct/ShBuyProductTabs");
  } else if (menuItem.attributes.url === "chgj/qpjJsProduct") {
    // 汽配经寄售仓产品
    menuItem.path = "/qpjJsProduct";
    addRouter("qpjJsProduct", menuItem.path, "qpjJsProduct/QpjJsProduct");
  } else if (menuItem.attributes.url === "https://www.qipeijing.com/") {
    // 汽配经
    menuItem.path = "/qipeijing";
  } else if (menuItem.attributes.url === "chgj/shStatistics") {
    // 财务统计-----财务管理
    menuItem.path = "/shStatistics";
    addRouter("shStatistics", menuItem.path, "shstatistics/ShStatistics");
  } else if (menuItem.attributes.url === "chgj/shStatistics/orderStatistics") {
    // 工单查询
    menuItem.path = "/shOrderStatistics";
    addRouter(
      "shOrderStatistics",
      menuItem.path,
      "shstatistics/ShOrderStatistics"
    );
  } else if (menuItem.attributes.url === "chgj/shGuaOrder") {
    // 挂账管理
    menuItem.path = "/shGuaOrder";
    addRouter("shGuaOrder", menuItem.path, "shorder/ShGuaOrder");
  } else if (
    menuItem.attributes.url === "chgj/shStatistics/productStatistics"
  ) {
    // 配件查询
    menuItem.path = "/shProductStatistics";
    addRouter(
      "shProductStatistics",
      menuItem.path,
      "shstatistics/ShProductStatistics"
    );
  } else if (menuItem.attributes.url === "chgj/shStatistics/gsStatistics") {
    // 工时查询
    menuItem.path = "/shGsStatistics";
    addRouter("shGsStatistics", menuItem.path, "shstatistics/ShGsStatistics");
  } else if (menuItem.attributes.url === "chgj/shStatistics/quanStatistics") {
    // 次卡销售查询
    menuItem.path = "/shQuanStatistics";
    addRouter(
      "shQuanStatistics",
      menuItem.path,
      "shstatistics/ShQuanStatistics"
    );
  } else if (menuItem.attributes.url === "chgj/shStatistics/cardStatistics") {
    // 充值卡销售查询
    menuItem.path = "/shCardStatistics";
    addRouter(
      "shCardStatistics",
      menuItem.path,
      "shstatistics/ShCardStatistics"
    );
  } else if (menuItem.attributes.url === "chgj/shTiCheng") {
    // 员工提成
    menuItem.path = "/shTiCheng";
    addRouter("shTiCheng", menuItem.path, "shstatistics/ShTiCheng");
  } else if (menuItem.attributes.url === "chgj/shCostList") {
    // 费用录入
    menuItem.path = "/shCostList";
    addRouter("shCostList", menuItem.path, "shstatistics/shCostList");
  } else if (menuItem.attributes.url === "chgj/shGs") {
    // 工时服务 ------- 基本资料
    menuItem.path = "/shGs";
    addRouter("shGs", menuItem.path, "shgs/ShGs");
  } else if (menuItem.attributes.url === "chgj/shPayType") {
    // 付款方式
    menuItem.path = "/shPayType";
    addRouter("shPayType", menuItem.path, "shpaytype/ShPayType");
  } else if (menuItem.attributes.url === "chgj/shGuaOrg") {
    // 挂账机构
    menuItem.path = "/shGuaOrg";
    addRouter("shGuaOrg", menuItem.path, "shguaorg/ShGuaOrg");
  } else if (menuItem.attributes.url === "chgj/repairTx") {
    // 我的账户 ------- 系统管理
    menuItem.path = "/repairTx";
    addRouter("repairTx", menuItem.path, "shaccount/RepairTx");
  } else if (menuItem.attributes.url === "chgj/chShop") {
    // 我的店铺
    menuItem.path = "/shshop";
    addRouter("shshop", menuItem.path, "shshop/ShShop");
  } else if (menuItem.attributes.url === "chgj/child") {
    // 员工管理
    menuItem.path = "/child";
    addRouter("child", menuItem.path, "child/Child");
  } else if (menuItem.attributes.url === "version/introduce") {
    // 功能介绍
    menuItem.path = "/introduce";
    addRouter("introduce", menuItem.path, "about/introduce");
  } else if (menuItem.attributes.url === "chgj/kefu") {
    /*****************下面是客服管理页面*****************/
    // 客服管理
    menuItem.path = "/kefu";
    addRouter("kefu", menuItem.path, "kefu/kefu/Kefu");
  } else if (menuItem.attributes.url === "sys/dept") {
    // 结构管理
    menuItem.path = "/dept";
    addRouter("dept", menuItem.path, "kefu/dept/Dept");
  } else if (menuItem.attributes.url === "chgj/gongGu") {
    // 公估结构管理
    menuItem.path = "/gongGu";
    addRouter("gongGu", menuItem.path, "kefu/gongGu/GongGu");
  } else if (menuItem.attributes.url === "chgj/brandSh") {
    // 品牌用户管理
    menuItem.path = "/brandSh";
    addRouter("brandSh", menuItem.path, "kefu/brandSh/BrandSh");
  } else if (menuItem.attributes.url === "chgj/cheZhu") {
    // 车主管理
    menuItem.path = "/cheZhu";
    addRouter("cheZhu", menuItem.path, "kefu/cheZhu/CheZhu");
  } else if (menuItem.attributes.url === "chgj/userWatch") {
    // 用户管理
    menuItem.path = "/userWatch";
    addRouter("userWatch", menuItem.path, "kefu/userWatch/UserWatch");
  } else if (menuItem.attributes.url === "chgj/chOcrRecord") {
    // 用户管理
    menuItem.path = "/chOcrRecord";
    addRouter("chOcrRecord", menuItem.path, "kefu/chOcrRecord/ChOcrRecord");
  } else if (menuItem.attributes.url === "chgj/loginLogs") {
    // 登录日志
    menuItem.path = "/loginLogs";
    addRouter("loginLogs", menuItem.path, "kefu/loginLogs/LoginLogs");
  } else if (menuItem.attributes.url === "chgj/smsSend") {
    // 查看短信
    menuItem.path = "/smsSend";
    addRouter("smsSend", menuItem.path, "kefu/smsSend/SmsSend");
  } else if (menuItem.attributes.url === "chgj/chFeedback") {
    // 用户反馈
    menuItem.path = "/chFeedback";
    addRouter("chFeedback", menuItem.path, "kefu/chFeedback/ChFeedback");
  } else if (menuItem.attributes.url === "chgj/chWelcome") {
    // 欢迎图片
    menuItem.path = "/chWelcome";
    addRouter("chWelcome", menuItem.path, "kefu/chWelcome/ChWelcome");
  } else if (menuItem.attributes.url === "chgj/scProduct") {
    // 我的产品 ---------------------商城管理----------------------
    menuItem.path = "/scProduct";
    addRouter("scProduct", menuItem.path, "kefu/scProduct/ScProduct");
  } else if (menuItem.attributes.url === "chgj/scOrder") {
    // 产品订单
    menuItem.path = "/scOrder";
    addRouter("scOrder", menuItem.path, "kefu/scOrder/ScOrder");
  } else if (menuItem.attributes.url === "chgj/chRefund") {
    // 售后管理
    menuItem.path = "/chRefund";
    addRouter("chRefund", menuItem.path, "kefu/scOrder/ChRefund");
  } else if (menuItem.attributes.url === "chgj/scDict") {
    // 产品分类
    menuItem.path = "/scDict";
    addRouter("scDict", menuItem.path, "kefu/scDict/ScDict");
  } else if (menuItem.attributes.url === "chgj/scSupplier") {
    // 产品供货商
    menuItem.path = "/scSupplier";
    addRouter("scSupplier", menuItem.path, "kefu/scSupplier/ScSupplier");
  } else if (menuItem.attributes.url === "chgj/scBrand") {
    // 产品品牌
    menuItem.path = "/scBrand";
    addRouter("scBrand", menuItem.path, "kefu/scBrand/ScBrand");
  } else if (menuItem.attributes.url === "chgj/shOrderWatch") {
    // 工单监控 ---------------------订单监控----------------------
    menuItem.path = "/shOrderWatch";
    addRouter("shOrderWatch", menuItem.path, "kefu/shOrderWatch/ShOrderWatch");
  } else if (menuItem.attributes.url === "chgj/shStatisticsWatch") {
    // 财务监控
    menuItem.path = "/shStatisticsWatch";
    addRouter(
      "shStatisticsWatch",
      menuItem.path,
      "kefu/shStatisticsWatch/ShStatisticsWatch"
    );
  } else if (menuItem.attributes.url === "chgj/useRecordWatch") {
    // 消费监控
    menuItem.path = "/useRecordWatch";
    addRouter(
      "useRecordWatch",
      menuItem.path,
      "kefu/useRecordWatch/UseRecordWatch"
    );
  } else if (menuItem.attributes.url === "chgj/orderWatch/quanOrder") {
    // 优惠券订单
    menuItem.path = "/quanOrder";
    addRouter("quanOrder", menuItem.path, "kefu/orderWatch/QuanOrder");
  } else if (menuItem.attributes.url === "chgj/orderWatch/czCardOrder") {
    // 充值卡订单
    menuItem.path = "/czCardOrder";
    addRouter("czCardOrder", menuItem.path, "kefu/orderWatch/CzCardOrder");
  } else if (menuItem.attributes.url === "chgj/scActivity") {
    // 活动管理 ---------------------商城优惠券----------------------
    menuItem.path = "/scActivity";
    addRouter("scActivity", menuItem.path, "kefu/scActivity/ScActivity");
  } else if (menuItem.attributes.url === "chgj/scQuan") {
    // 商城优惠券管理
    menuItem.path = "/scQuan";
    addRouter("scQuan", menuItem.path, "kefu/scQuan/ScQuan");
  } else if (menuItem.attributes.url === "chgj/tcQuan") {
    // 套餐管理 ---------------------套餐管理----------------------
    menuItem.path = "/tcQuan";
    addRouter("tcQuan", menuItem.path, "kefu/tcQuan/TcQuan");
  } else if (menuItem.attributes.url === "chgj/tcOrder") {
    // 套餐订单
    menuItem.path = "/tcOrder";
    addRouter("tcOrder", menuItem.path, "kefu/tcOrder/TcOrder");
  } else if (menuItem.attributes.url === "chgj/tcQuanAd") {
    // 套餐广告
    menuItem.path = "/tcQuanAd";
    addRouter("tcQuanAd", menuItem.path, "kefu/tcQuanAd/TcQuanAd");
  } else if (menuItem.attributes.url === "chgj/tcDict") {
    // 套餐分类
    menuItem.path = "/tcDict";
    addRouter("tcDict", menuItem.path, "kefu/tcDict/TcDict");
  } else if (menuItem.attributes.url === "chgj/tuan") {
    // 团购管理 ---------------------团购管理----------------------
    menuItem.path = "/tuanList";
    addRouter("tuanList", menuItem.path, "kefu/tuanList/TuanList");
  } else if (menuItem.attributes.url === "chgj/tuanOrder") {
    // 团购订单
    menuItem.path = "/tuanOrder";
    addRouter("tuanOrder", menuItem.path, "kefu/tuanOrder/TuanOrder");
  } else if (menuItem.attributes.url === "chgj/userTx") {
    // 客户提现 ---------------------提现管理----------------------
    menuItem.path = "/userTx";
    addRouter("userTx", menuItem.path, "kefu/userTx/UserTx");
  } else if (menuItem.attributes.url === "chgj/userAccount") {
    // 账户审核
    menuItem.path = "/userAccount";
    addRouter("userAccount", menuItem.path, "kefu/userAccount/UserAccount");
  } else if (menuItem.attributes.url === "chgj/kefu/kfBalanceMx") {
    // 客服账户 ---------------------公司财务统计----------------------
    menuItem.path = "/kfBalanceMx";
    addRouter("kfBalanceMx", menuItem.path, "kefu/kfBalanceMx/KfBalanceMx");
  } else if (menuItem.attributes.url === "chgj/kefu/kfJs") {
    // 客服账户结算
    menuItem.path = "/kfJs";
    addRouter("kfJs", menuItem.path, "kefu/kfJs/KfJs");
  } else if (menuItem.attributes.url === "chgj/kefu/orderPay") {
    // 全部支付记录
    menuItem.path = "/orderPay";
    addRouter("orderPay", menuItem.path, "kefu/orderPay/OrderPay");
  } else if (menuItem.attributes.url === "chgj/chFinance") {
    // 财务统计
    menuItem.path = "/chFinance";
    addRouter("chFinance", menuItem.path, "kefu/chFinance/ChFinance");
  } else if (menuItem.attributes.url === "chgj/caPiGu") {
    // 异常处理
    menuItem.path = "/caPiGu";
    addRouter("caPiGu", menuItem.path, "kefu/caPiGu/CaPiGu");
  } else if (menuItem.attributes.url === "chgj/jyOrder") {
    // 搭电救援 ---------------------搭电救援----------------------
    menuItem.path = "/jyOrder";
    addRouter("jyOrder", menuItem.path, "kefu/jyOrder/JyOrder");
  } else if (menuItem.attributes.url === "chgj/market/deptFx") {
    // 修理厂分销 ---------------------分销管理----------------------
    menuItem.path = "/deptFx";
    addRouter("deptFx", menuItem.path, "kefu/deptFx/DeptFx");
  } else if (menuItem.attributes.url === "chgj/fxUser") {
    // 用户关系
    menuItem.path = "/fxUser";
    addRouter("fxUser", menuItem.path, "kefu/fxUser/FxUser");
  } else if (menuItem.attributes.url === "chgj/fxUser/fxUserChart") {
    // 用户关系
    menuItem.path = "/fxUserChart";
    addRouter("fxUserChart", menuItem.path, "kefu/fxUser/FxUserChart");
  } else if (menuItem.attributes.url === "chgj/chPayMch") {
    // 商户管理 ---------------------余额付款管理----------------------
    menuItem.path = "/chPayMch";
    addRouter("chPayMch", menuItem.path, "kefu/chPayMch/ChPayMch");
  } else if (menuItem.attributes.url === "chgj/chPayRecord") {
    // 付款记录
    menuItem.path = "/chPayRecord";
    addRouter("chPayRecord", menuItem.path, "kefu/chPayRecord/ChPayRecord");
  } else if (menuItem.attributes.url === "chgj/xlcAddress") {
    // 门店采集 ---------------------门店采集----------------------
    menuItem.path = "/xlcAddress";
    addRouter("xlcAddress", menuItem.path, "kefu/xlcAddress/XlcAddress");
  } else if (menuItem.attributes.url === "chgj/tuiXlc") {
    // 门店采集 ---------------------车主推荐修理厂----------------------
    menuItem.path = "/tuiXlc";
    addRouter("tuiXlc", menuItem.path, "kefu/xlcAddress/TuiXlc");
  } else if (menuItem.attributes.url === "sys/role") {
    // 角色管理 ---------------------系统管理----------------------
    menuItem.path = "/sysrole";
    addRouter("sysrole", menuItem.path, "kefu/sysrole/SysRole");
  } else if (menuItem.attributes.url === "sys/menu") {
    // 菜单管理
    menuItem.path = "/sysmenu";
    addRouter("sysmenu", menuItem.path, "kefu/sysmenu/SysMenu");
  } else if (menuItem.attributes.url === "chgj/chWxMenu") {
    // 微信小程序菜单
    menuItem.path = "/chWxMenu";
    addRouter("chWxMenu", menuItem.path, "kefu/chWxMenu/ChWxMenu");
  } else if (menuItem.attributes.url === "chgj/scHomeArea") {
    // 商城小程序首页
    menuItem.path = "/scHomeArea";
    addRouter("scHomeArea", menuItem.path, "kefu/scHomeArea/ScHomeArea");
  } else if (menuItem.attributes.url === "chgj/chLight") {
    // 指示灯图解
    menuItem.path = "/chLight";
    addRouter("chLight", menuItem.path, "kefu/chLight/ChLight");
  } else if (menuItem.attributes.url === "common/generator") {
    // 代码生成 ---------------------系统工具----------------------
    menuItem.path = "/generator";
    addRouter("generator", menuItem.path, "kefu/generator/Generator");
  } else if (menuItem.attributes.url === "common/job") {
    // 计划任务
    menuItem.path = "/job";
    addRouter("job", menuItem.path, "kefu/job/Job");
  } else if (menuItem.attributes.url === "common/log") {
    // 系统日志
    menuItem.path = "/syslog";
    addRouter("syslog", menuItem.path, "kefu/syslog/SysLog");
  }
};
const addRouter = (name: string, path: string, componentName: string) => {
  const component = modules["/src/views/" + componentName + ".vue"];
  router.addRoute("index", {
    name,
    path,
    component,
  });
  menuStore.addItem({
    name,
    path,
    componentName,
  });
};

const submitLogout = () => {
  showLogout.value = false;
  storage.clear();
  userInfoStore.saveUserInfo({ id: "" });
  menuStore.clearAll(); // 清除菜单
  proxy.$router.push("/login");
};
</script>
